import React, { useState, useEffect } from "react";
import { storage, db } from "./firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs } from "firebase/firestore";
import { Upload, Button, message, Progress, List,Typography } from "antd";
import { InboxOutlined, DownloadOutlined } from "@ant-design/icons";

const { Dragger } = Upload;
const { Title } = Typography;

const UploadPage = () => {
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleUpload = async ({ file }) => {
    setUploading(true);
    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.round(percent));
      },
      (error) => {
        message.error("Upload failed");
        setUploading(false);
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        await addDoc(collection(db, "files"), { name: file.name, url });
        message.success("File uploaded successfully!");
        setUploading(false);
        setProgress(0);
      }
    );
  };

  return (
    <div style={{ maxWidth: 500, margin: "auto", padding: 20 }}>
      <h2>Upload ZIP File</h2>
      <Dragger
        accept=".zip"
        customRequest={handleUpload}
        showUploadList={false}
        multiple={false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">Only ZIP files are allowed</p>
      </Dragger>
      {uploading && <Progress percent={progress} />}
    </div>
  );
};

const DownloadPage = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFiles = async () => {
      const querySnapshot = await getDocs(collection(db, "files"));
      setFiles(querySnapshot.docs.map((doc) => doc.data()));
    };
    fetchFiles();
  }, []);

  return (
    <div style={{ maxWidth: 600, margin: "auto", padding: 20, background: "#fff", borderRadius: 10, boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)" }}>
      <Title level={2} style={{ textAlign: "center", color: "#ff6f61" }}>🎀 Sreemantham Photos 🎀</Title>
      <List
        bordered
        dataSource={files}
        renderItem={(file) => (
          <List.Item>
            {file.name} 
            <Button type="link" href={file.url} download icon={<DownloadOutlined />}>Download</Button>
          </List.Item>
        )}
      />
    </div>
  );
};

export { UploadPage, DownloadPage };
